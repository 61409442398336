@font-face {
  font-family: 'Caja-md';
  src: url("./fonts/MetaPro-Medium.otf") format("opentype");
}

@font-face {
  font-family: 'Caja-Black';
  src: url("./fonts/MetaPro-Black.otf") format("opentype");
}

@font-face {
  font-family: 'Caja-Black-Italic';
  src: url("./fonts/MetaPro-BlackItalic.otf") format("opentype");
}
@font-face {
  font-family: 'Black-OP';
  src: url("./fonts/BlackOpsOne-Regular.otf") format("opentype");
}
@font-face {
  font-family: 'Black-Regular';
  src: url("./fonts/ArchivoBlack-Regular.otf") format("opentype");
}
@font-face {
  font-family: 'Pacifico-Regular';
  src: url("./fonts/Pacifico-Regular.otf") format("opentype");
}
@font-face {
  font-family: 'Montserrat-pdf';
  src: url("./fonts/Montserrat-VariableFont_wght.otf") format("opentype");
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

#root {
  /* background-image: url("/src/img/fondo-cpa.jpg"); */
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
}

.mainSection {
  /* para bajar footer */
  flex-grow: 1;
}

.letraCaja {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: larger;
  margin-bottom: 0;
}

.footer{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #DDDDDD;
}
.tooltip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 4px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 9999;
  top: -30px;
  /* Ajusta la posición vertical del tooltip */
  left: 50%;
  /* Centra el tooltip horizontalmente */
  transform: translateX(-50%);
  white-space: nowrap;
  /* Evita que el texto se desborde */
}

.iconAntD {
  margin-right: 3px;
}

.botonCPA {
  background: rgb(27, 117, 107);
  color: rgb(255, 255, 255);
  border: 2px solid rgb(27, 117, 107);
  border-radius: 9999px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  width: max-content;
}

.botonCPA:disabled {
  background: #58595B;
  color: rgb(255, 255, 255);
  border: 2px solid #58595B;
  border-radius: 9999px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.botonCPA-Excel {
  background: rgb(27, 117, 107);
  color: rgb(255, 255, 255);
  border: 2px solid rgb(27, 117, 107);
  border-radius: 9999px;
  padding: 5px 10px;
  text-transform: uppercase;
}

.botonCPA-PDF {
  background: #EC1B23;
  color: rgb(255, 255, 255);
  border: 2px solid #EC1B23;
  border-radius: 9999px;
  padding: 5px 10px;
  text-transform: uppercase;
}

.PDF-Download {
  text-decoration: none;
}

.botonCPA:hover {
  border: 2px solid #F15E21;
  background: #F15E21;
}

.botonCPA-PDF:hover {
  border: 2px solid #F15E21;
  background: #F15E21;
}

.colorMain {
  background: rgb(168, 163, 163);
}

img {
  width: 200px;
}

.success-message {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dff0d8;
  color: #008185;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.success-message>i {
  font-size: 24px;
  margin-right: 10px;
  color: #3c763d;
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}


.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.input-field {
  flex: 1;
  font-size: 16px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.dropzone {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  background-color: #f9f9f9;
  outline: none;
  margin-bottom: 20px;
}

.red-link {
  text-decoration: none;
  /* Quitar el subrayado */
  color: #F10000;
  /* Cambiar el color del texto a rojo */
}

.card-container {
  width: 300px;
  /* Ajusta el ancho según tus necesidades */
  border: 1px solid #ccc;
  /* Bordes y estilos de tarjeta personalizables */
  border-radius: 12px;
  /* Establece un radio para toda la tarjeta */
  overflow: hidden;
  /* Oculta el contenido que se sale de la tarjeta */
  background-color: #fff;
  /* Color de fondo de la tarjeta */
  position: relative;
  /* Permite posicionar el contenedor de forma relativa */

}

.nav-link:not(.active) {
  color: black;
  /* Cambia el color del texto a rojo */
}

h1 {
  margin: 0;
  /* Elimina el margen predeterminado del encabezado */
}

/*Estilos Login*/
/*Estilos Login*/
#ComponenteDelante {
  position: relative;
  z-index: 2;
  top: 55px;
}

#ComponenteFijo {
  position: relative;
  width: 100%;
  /* margin-top: 10%; */
  border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.circulo {
  position: absolute;
  width: 110px;
  height: 110px;
  background-image: url(./img/logo-quiniela-login.png); /*Aqui va la url del logo*/
  background-size: cover;
  border-radius: 50%;
  background-color: white;
  margin:0 auto;
  background-position: center center;
  background-color: white;
  background-size: cover;
  background-size: 150%;
}

.card-side{
  width: 8px;
  height: 190px;
  border-radius: 0px 9px 9px 0px;
  background: #008185;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}


.card-options{
  width: 280px;
  height: 190px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index:1;
  border-radius: 9px 0px 0px 9px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.text-options{
  z-index:2;
  font-style: bold;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  margin-left: 20px;
  color: #000000;
  margin-top: 30px;
}

.img-options{
  z-index:2;
  width: 72px;
  height: 82px;
  margin-top: 20px;
  align-self: center;
  margin-left: -15px;
}

.card-seleccion:hover {
  position: relative;
  top: -2px;
  right: 2px;
  filter: grayscale(0%);
  transition: 700ms;
  transform: scale(1.1);
}

.text-foot{
  color: #6c757d!important;
  font-family: 'Caja-Black';
  font-size:larger;
  font-weight: bolder;
}

.react-calendar__tile.selected-date {
  background-color: #008185 !important; /* Asegúrate de usar !important */
  color: white !important;
  border-radius: 50%; /* Si deseas bordes redondeados */
}

.red-day {
  color: red !important; /* Texto rojo */
}
